/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/css/tg-modal.css';
import './src/css/index.css';
import './src/scss/index.scss';
import ReactDOM from 'react-dom/client';

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
export const onClientEntry = () => {
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer');
    console.log('# IntersectionObserver is polyfilled!');
  }
};

// Activates Google Optimize experiments
export const onInitialClientRender = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'optimize.activate' });
};

// Custom hydrate function using React 18's createRoot API to fix Minified React errors
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
